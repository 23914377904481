import global from './global';
import ceo from './ceo';

/**
 * This is the root of the fr lang
 */
export default {
  translation: {
    global: global,
    ceo: ceo,
  },
};
