/**
 * The global en lang resources
 *
 * Global resources is for translation that appear on many pages
 */
export default {
  about_me: 'about me',
  formation: 'formation',
  formations: 'formations',
  hi: 'hi',
  me: 'me',
  project: 'project',
  projects: 'projects',
  skill: 'skill',
  skills: 'skills',
  welcome: 'welcome',
};
