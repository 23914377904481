/**
 * The global fr lang resources
 *
 * Global resources is for translation that appear on many pages
 */
export default {
  about_me: 'a propos de moi',
  formation: 'formation',
  formations: 'formations',
  hi: 'salut',
  me: 'moi',
  project: 'projet',
  projects: 'projets',
  skill: 'compétence',
  skills: 'compétences',
  welcome: 'Bienvenue',
};
