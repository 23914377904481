import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/home/HomePage';
import BaseLayout from '../layout/base/BaseLayout';
import AboutMePage from '../pages/about_me/AboutMePage';
import ProjectPage from '../pages/projects/ProjectPage';
import FormationPage from '../pages/formations/FormationPage';
import SkillPage from '../pages/skills/SkillPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: '/me',
        element: <AboutMePage />,
      },
      {
        path: '/skills',
        element: <SkillPage />,
      },
      {
        path: '/formations',
        element: <FormationPage />,
      },
      {
        path: '/projects',
        element: <ProjectPage />,
      },
    ],
  },
]);

export default router;
