import React, { useState } from 'react';
import { availableLang } from '../../../app/lang/i18n';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

const HeaderLang: React.FC = () => {
  const { i18n } = useTranslation();

  const [dropdown, setDropdown] = useState(false);

  const handleDropdownToggle = () => {
    const newVal = !dropdown;
    if (newVal) {
      document.addEventListener('click', function close(evt) {
        const { id } = evt.target as HTMLElement;
        if (id !== 'lang-toggle-button') {
          setDropdown(false);
          document.removeEventListener('click', close);
        }
      });
    }
    setDropdown(!dropdown);
  };

  return (
    <div>
      <button
        id="lang-toggle-button"
        type="button"
        aria-controls="lang-dropdrown-menu"
        className="header_layout_options_langs_toggle"
        onClick={handleDropdownToggle}
      >
        {i18n.language}
      </button>
      <CSSTransition in={dropdown} timeout={500} classNames="lang_dropdown" unmountOnExit>
        <div id="lang-dropdrown-menu" className="header_layout_options_langs" role="menu">
          {Object.keys(availableLang).map((lang) => (
            <div
              key={lang}
              id={lang}
              role="menuitem"
              className="header_layout_options_langs_item"
              tabIndex={-1}
              onClick={() => i18n.changeLanguage(lang)}
            >
              {/*{lang.toUpperCase()} */}
              {availableLang[lang].nativeName}
            </div>
          ))}
        </div>
      </CSSTransition>
    </div>
  );
};

export default HeaderLang;
