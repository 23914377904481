import React from 'react';
import HeaderLayout from '../header/HeaderLayout';
import { Outlet } from 'react-router-dom';
import FooterLayout from '../footer/FooterLayout';
import { Helmet } from 'react-helmet';

import '../../assets/sass/main.scss';
import { useTranslation } from 'react-i18next';

import image from '../../assets/images/media.png';

const BaseLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <meta name="description" content={t('ceo.description')} />
        <meta name="keyword" content="romeo tatchemo, portfolio, developer" />
        <meta name="author" content="Romeo Tatchemo" />

        <meta property="og:title" content="Romeo Tatchemo - Portfolio" />
        <meta property="og:description" content={t('ceo.description')} />
        <meta property="og:image" content={image} />
        {/*<meta*/}
        {/*  property="og:url"*/}
        {/*  content="https://www.example.com/ceo-social-media"*/}
        {/*/>*/}
        <meta property="og:type" content="profile" />

        {/*Twitter Card Meta Tags*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Romeo Tatchemo - Portfolio" />
        <meta name="twitter:description" content={t('ceo.description')} />
        <meta name="twitter:image" content={image} />

        {/*LinkedIn Meta Tags*/}
        <meta property="linkedin:card" content="summary_large_image" />
        <meta property="linkedin:title" content="Romeo Tatchemo - Portfolio" />
        <meta property="linkedin:description" content={t('ceo.description')} />
        <meta property="linkedin:image" content={image} />
      </Helmet>
      <HeaderLayout />
      <main>
        <Outlet />
      </main>
      <FooterLayout />
    </>
  );
};

export default BaseLayout;
