import React from 'react';
import { useTranslation } from 'react-i18next';
import './homepage.scss';

const HomePage: React.FC = () => {
  // const { t } = useTranslation();
  return (
    <div className="homepage">
      <section className="homepage_container">
        <div className="homepage_info_container">
          <div className="homepage_info_container_bar"></div>
          <div className="homepage_info_container_bar_v"></div>
          <div>
            <h1>Romeo Tatchemo</h1>
          </div>
        </div>

        <div className="homepage_title_container">
          <h3>FullStack Developer / Power BI Analyst</h3>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
