import React, { useEffect, useRef, useState } from 'react';
import './header.scss';
import HeaderLang from './lang/HeaderLang';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiMenu } from '@mdi/js';
import { CSSTransition } from 'react-transition-group';

// the responsive break point
const responsiveBreakPoint = 750;

/**
 * The app header layout. it contains:
 * * Navigation link
 * * Lang change dropdown
 * * The logic is that when window width is over breakpoint, the nave should be displayed by default. this is manage by menuToggle state
 */
const HeaderLayout: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const navRef = useRef<HTMLDivElement>(null);

  const [menuToggle, setMenuToggle] = useState<boolean>(
    window.innerWidth > responsiveBreakPoint,
  );

  /**
   * Evaluate the window width and a bool if name menu should be toggled or not
   *
   */
  const handleViewResize = () => {
    if (window.innerWidth > responsiveBreakPoint) {
      setMenuToggle(true);
    } else {
      setMenuToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleViewResize);

    return () => {
      window.removeEventListener('resize', handleViewResize);
    };
  }, []);

  useEffect(() => {
    if (menuToggle) {
      document.addEventListener('click', function close(event) {
        // extract the targeted element id
        const { id } = event.target as HTMLElement;

        if (
          id !== 'nav-toggle-button' &&
          window.innerWidth <= responsiveBreakPoint // ensure that whe are in mobile size
        ) {
          // remove listener
          document.removeEventListener('click', close);
          //update state
          setMenuToggle(false);
        }
      });
    }
  }, [menuToggle]);

  return (
    <header className="header_layout">
      <div className={`header_layout_nav_container ${menuToggle && 'd-block'}`}>
        <CSSTransition
          in={menuToggle}
          timeout={200}
          classNames="nav-toggle"
          unmountOnExit
        >
          <nav ref={navRef} className="header_layout_nav">
            <Link
              to="/"
              className={`header_layout_nav_item ${location.pathname === '/' && 'header_layout_nav_item_active'}`}
            >
              {t('global.me')}
            </Link>
            <Link
              to="/me"
              className={`header_layout_nav_item ${location.pathname === '/me' && 'header_layout_nav_item_active'}`}
            >
              {t('global.about_me')}
            </Link>
            <Link
              to="/skills"
              className={`header_layout_nav_item ${location.pathname === '/skills' && 'header_layout_nav_item_active'}`}
            >
              {t('global.skills')}
            </Link>
            <Link
              to="/formations"
              className={`header_layout_nav_item ${location.pathname === '/formations' && 'header_layout_nav_item_active'}`}
            >
              {t('global.formations')}
            </Link>
            <Link
              to="/projects"
              className={`header_layout_nav_item ${location.pathname === '/projects' && 'header_layout_nav_item_active'}`}
            >
              {t('global.projects')}
            </Link>
            {}
          </nav>
        </CSSTransition>
      </div>
      <span
        style={{
          display: 'block',
          position: 'relative',
        }}
      >
        <Icon path={mdiMenu} size={1.5} className="header_layout_nav_toggle" />
        <div
          onClick={() => setMenuToggle(!menuToggle)}
          id="nav-toggle-button"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            backgroundColor: 'transparent',
          }}
        ></div>
      </span>
      <div id="header_options" className="header_layout_options">
        <HeaderLang />
      </div>
    </header>
  );
};

export default HeaderLayout;
