import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en/en';
import fr from './fr/fr';

interface Language {
  [key: string]: { nativeName: string };
}

export const availableLang: Language = {
  en: { nativeName: 'English' },
  fr: { nativeName: 'Français' },
};

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    resources: {
      en: en,
      fr: fr,
    },
  });

export default i18n;
